import { getFlagsForConversationSummary, getFlagsForRU } from '../Apps/ContactCenter/Api/api'

export const ENABLE_SUMMARY = 'EnableSummary'
export const ENABLE_RU = 'EnableRUWidget'

export async function enableConvSummaryByQueue(queue) {
    const floodgateResponse: any = await getFlagsForConversationSummary(queue) || {};
    const featureFlags = floodgateResponse?.data?.releases[0]?.features || []
    const isAllowedFromFG = featureFlags?.includes(ENABLE_SUMMARY)
    
    console.log('Conversation Summary Enabled By Queue:', isAllowedFromFG)

    return isAllowedFromFG || false;
}

export async function enableRUByQueue(queue, agentLdap) {
    const floodgateResponse: any = await getFlagsForRU(queue, agentLdap) || {};
    const featureFlags = floodgateResponse?.data?.releases[0]?.features || []
    const isAllowedFromFG = featureFlags?.includes(ENABLE_RU)
    
    console.log('RU Enabled By Queue:', isAllowedFromFG)

    return isAllowedFromFG || false;
}