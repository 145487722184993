import env_variables from '../../../config/env_variables'
import { apiClient } from './axiosSettings'
import getAuthHeaders from './getAuthHeaders'

export async function getConversationId(chatId: any) {
  return await apiClient.get(`${env_variables.CCP_CH_URL}/api/histories/conversation/${chatId}`, { headers: getAuthHeaders() })
}

export async function updateContactAttributes(payload: any) {
  if(!Object.keys(payload?.attributes).length) return false
  const updated = await apiClient
    .post(`${env_variables.CCP_API_END_POINT}/api/updateContactAttributes`, payload, { headers: getAuthHeaders() })
    .then(() => true)
    .catch((error: any) => {
      console.log('updateContactAttributes error: ', error)
      return false
    })
  return updated
}

export async function outboundMetadata(payload: any) {
  const updated = await apiClient
    .post(`${env_variables.CCP_API_END_POINT}/api/outbound/metadata`, payload, { headers: getAuthHeaders() })
    .then(() => true)
    .catch((error: any) => {
      console.log('outboundCallMetadata error: ', error)
      return false
    })
  return updated
}

export async function getConversationHistory(customerID: string) {
  const result = await apiClient.get(`${env_variables.CCP_CH_URL}/api/histories/customer?customerId=${customerID}`, { headers: getAuthHeaders() })
  return result
}

export async function getAllowedAgent(conversationId: string, agentId: string, linkedConversationId: string) {
  const result = await apiClient.get(
    `${env_variables.CCP_API_END_POINT}/api/v1/widget/allowedagent?conversationId=${conversationId}&agentId=${agentId}&linkedConversationId=${linkedConversationId}`,
    {
      headers: getAuthHeaders()
    }
  )
  return result
}

export async function stopRecording(payload: any) {
  const updated = await apiClient
    .post(`${env_variables.CCP_API_END_POINT}/api/record/stop`, payload, { headers: getAuthHeaders() })
    .then(() => true)
    .catch((error: any) => {
      console.log('Disable Recording Failure: ', error)
      return false
    })
  return updated
}

export async function getReleaseNotes() {
  try {
    const result = await apiClient.get(`${env_variables.CCP_API_END_POINT}/api/v1/ces/releaseNotes?active=true`, {
      headers: getAuthHeaders()
    })
    return result
  } catch (e) {
    return {}
  }
}

export async function getReleaseNotesPDF(version) {
  try {
    const result = await apiClient.get(`${env_variables.CCP_API_END_POINT}/api/v1/ces/releaseNotes/pdf?version=${version}`, {
      headers: getAuthHeaders(),
      responseType: 'blob'
    })
    return { success: true, result }
  } catch (e) {
    return { success: false }
  }
}
export async function updateVpnDisconnectedContactId(payload) {
  try {
    const result = await apiClient.put(`${env_variables.CCP_API_END_POINT}/api/v1/ces/vpndisconnect`, payload, { headers: getAuthHeaders() })
    return { success: true, result }
  } catch (e) {
    return { success: false }
  }
}

export async function getFeatureFlags(payload: any, url?: string) {
  const { agentId = '' } = payload
  try {
    const featureFlagsResponse = await apiClient.get(`${url ?? env_variables.FG_ENDPOINT_CONVO_SUMMARY}&_agentLdp=${agentId}`, {
      headers: { 'x-api-key': 'oac-custom-panel', Authorization: localStorage.getItem('customPanelAccessToken').replaceAll('"', '') ?? '' }
    })
    return featureFlagsResponse
  } catch (error) {
    console.log('getFeatureFlags error: ', error)
    return {}
  }
}

export async function getFlagsForConversationSummary(queue: string = '') {
  try {
    const featureFlagsResponse = await apiClient.get(`${env_variables.FG_ENDPOINT_CONVO_SUMMARY}&_queue=${queue}`, {
      headers: { 'x-api-key': 'oac-custom-panel', Authorization: localStorage.getItem('customPanelAccessToken').replaceAll('"', '') ?? '' }
    })
    return featureFlagsResponse
  } catch (error) {
    console.log('getFlagForConversationSummary error: ', error)
    return {}
  }
}

export async function getFlagsForRU(queue: string = '', agentLdap = '') {
  try {
    const featureFlagsResponse = await apiClient.get(`${env_variables.FG_ENDPOINT_RU}&_queue=${queue}&_agentLdp=${agentLdap}`, {
      headers: { 'x-api-key': 'oac-custom-panel', Authorization: localStorage.getItem('customPanelAccessToken').replaceAll('"', '') ?? '' }
    })
    return featureFlagsResponse
  } catch (error) {
    console.log('getFlagForConversationSummary error: ', error)
    return {}
  }
}
export async function getTranslationData(reqData: any, headers: { Authorization: string; 'x-api-key': string; }) {
  try {
    const response = await apiClient.post(`${reqData.endpoint}/api/translate/chat`, reqData.body, {headers: headers });
    return response;
  } catch(e) {
    console.log('getTranslationData error: ', e)
  }
 
}